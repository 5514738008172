<ng-container *ngIf="!item.hidden">

<!-- item.url CONTACTO -->

    <!-- item.url -->
    <a class="nav-link"  [ngClass]="item.classes" *ngIf="(!esCampaign && !desactivado && item.id !== 'email') || (globalService.campaignAivoz && esCampaign && !desactivado)"
        [routerLink]="[item.url]" 
        [routerLinkActive]="['active', 'accent']"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a> 

    <a class="nav-link"  [ngClass]="item.classes" *ngIf="(globalService.campaignAivoz === null) && (esCampaign) && (!desactivado)"
        style="color: rgb(95, 95, 99);">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a> 

    <a class="nav-link"  [ngClass]="item.classes" *ngIf="(globalService.campaignAivoz== null) &&  (desactivado)"
    style="color: rgb(213, 221, 163);"
    (click)="proximamente()">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a> 

    <a class="nav-link" [ngClass]="item.classes" *ngIf="desactivado && !globalService.campaignAivoz"
    style="color: rgb(151, 130, 106);"
    (click)="proximamente()">
     <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.id === 'email'"
    (click)="contacto()">
     <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>


<!--
    <a class="nav-link"  
    [ngClass]="item.classes"
     *ngIf="!activo && item.url">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a> 

-->
    <!-- item.url PROVISIONAL
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && !item.function && esProvisional"
       
    (click)="proximamente(item)">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
 -->
  
<!--
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && !item.function && item.id=='tools-calendario'"
       (click)="contacto()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && !item.function && item.id=='tools-calendario'"
    (click)="contacto()">
     <ng-container *ngTemplateOutlet="itemContent"></ng-container>
 </a>

     item.url CONTACTO 
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && !item.function && item.id=='contactEmail'"
       (click)="contacto()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
-->
    <!-- item.externalUrl 
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && !item.function"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>-->

    <!-- item.function 
    <span class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && item.function"
          (click)="item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>-->

    <!-- item.url && item.function 
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && item.function"
       (click)="item.function()"
       [routerLink]="[item.url]" [routerLinkActive]="['active', 'accent']"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
    -->
    <!-- item.externalUrl && item.function 
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && item.function"
    (click)="item.function()"
    [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
</a>
-->
    <ng-template #itemContent>
                <mat-icon class="nav-link-icon" *ngIf="item.icon">{{item.icon}}</mat-icon>
                <span class="nav-link-title" [translate]="item.translate">{{(item.translate | translate) || item.title}}</span>
      </ng-template>

</ng-container>
