export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'HOME': 'Inicio',
            'SECTIONS': 'Secciones',
            'TOOLS': 'Herramientas',
            'SINPA': 'Sinpa',
            'DOCUMENTS': 'CAMPAÑA',
            'EVENTS': 'Eventos',
            'TEMPLATES': 'Crear una firma',
            'LIST_TEMPLATES': 'Lista de documentos',
            'CREATE_TEMPLATES': 'Crear documento',
            'VERIFICATION':'Ver llamada',
            'SETTINGS': 'Ajustes',
            'USER_SETTINGS': 'Ajustes de usuario',
            'BIOMETRIC_SIGNATURES': 'Ver Campañas',
            'QUICK_SIGNATURES': 'Ver Firmas Sencillas',
            'DIGITAL_FILE': 'ADOC-Archivos compartidos',
            'TIME_CONTROL': 'Control horario',
            'CONTACT': 'Contacta',
            'CONTACT_EMAIL': 'Email de contacto',
            'HELP': 'Ayuda',
            'OTHER_SECTIONS': 'Otras secciones',
            'MANUAL': 'Manual',
            'VIDEOS': 'Vídeos',
            'SBK_IDENTITY': 'SBK Identity',
            'MAIL_BUROFAX': 'Mailburofax',
            'VIDEO': 'Video',
            'BETA': 'Beta',
            'ONE_SHOT': 'Firma One Shot',
            'NEW': 'Nuevo'
        }
    }
};
