import { FuseNavigation } from '@fuse/types';


export const navigation: FuseNavigation[] = [
    {
        id: 'sections',
        title: 'Sections',
        translate: 'NAV.SECTIONS',
        type: 'group',
        icon: 'apps',
        
    }, 
    {
        id: 'documents',
        title: 'Documents',
        translate: 'NAV.DOCUMENTS',
        type: 'group',
        icon: 'apps',
        children: [
           /* {   
                id: 'nuevaCampaña',
                title: 'Crear Campaña',
                translate: 'Crear Campaña',
                type: 'item',
                icon: 'add_circle',
                url: '/pages/nueva',
                exactMatch: true
            },*/
            {   
                id: 'campañas',
                title: 'Listado llamadas',
                translate: 'Listado llamadas',
                type: 'item',
                icon: 'label_important',
                url: '/apps/documents/events',
                exactMatch: true
            }
        ]
    },
    {
        id: 'actualCampaign',
        title: 'OPCIONES CAMPAÑA',
        translate: '',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'camp-opciones',
                title: 'saludo',
                translate: 'Editar Campaña',
                type: 'item',
                icon: 'memory',
                url: '/pages/opciones',
                exactMatch: true,
               
            },
            {   
                id: 'camp-grafico',
                title: 'Resumen',
                translate: 'Gráfico Campaña',
                type: 'item',
                icon: 'list',
                url: '/apps/resumen',
                exactMatch: true,
               
            },           
            {   
                id: 'camp-leads',
                title: 'Leads',
                translate: 'Leads',
                type: 'item',
                icon: 'sentiment_satisfied_alt',
                url: '/pages/lead',
                exactMatch: true,
               
            }
            
        ]
    },
    {
        id: 'tools',
        title: 'Tools',
        translate: 'HERRAMIENTAS',
        type: 'group',
        icon: 'apps',
        children: [
            {   
                id: 'camp-analisis',
                title: 'Resumen',
                translate: 'Análisis Campaña',
                type: 'item',
                icon: 'data_usage',
                url: '/pages/analisis',
                exactMatch: true,
               
            },
            {   
                //id: 'herra-clientes',
                id: 'off-fake',
                title: 'Verificaciones Fake',
                translate: 'Verificaciones Fake',
                type: 'item',
                icon: 'swap_calls',
                url: '/pages/fake',
                exactMatch: false
            },
            {   
                //id: 'herra-clientes',
                id: 'off-emocional',
                title: 'Validación Llamada',
                translate: 'Validación Llamada',
                type: 'item',
                icon: 'swap_calls',
                url: '/pages/clientes',
                exactMatch: false
            },
            {   
                //id: 'herra-clientes',
                id: 'off-fake',
                title: 'Verificaciones Fake',
                translate: 'Verificaciones Fake',
                type: 'item',
                icon: 'swap_calls',
                url: '/pages/clientes',
                exactMatch: false
            },
            {   
                //id: 'herra-clientes',
                id: 'off-clientes',
                title: 'Lista Clientes',
                translate: 'Lista Clientes',
                type: 'item',
                icon: 'swap_calls',
                url: '/pages/clientes',
                exactMatch: false
            },
            {   
                id: 'off-envios',
                title: 'Enviossss',
                translate: 'Envios',
                type: 'item',
                icon: 'mail_outline',
                externalUrl: false,
                url: '/apps/timeControl',
                exactMatch: false
            },
            {  
                id: 'off-calendario',
                title: 'Calendario',
                translate: 'Calendario',
                type: 'item',
                icon: 'calendar_today',
                url: '/apps/timeControl',
                exactMatch: false
            },
            {   
                id: 'off-voces',
                title: 'voces',
                translate: 'Voces',
                type: 'item',
                icon: 'mic',
                url: '/apps/timeControl',
                exactMatch: false,
                badge    : {
                    title    : 'BETA',
                    translate: 'NAV.BETA',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {   
                id: 'off-control',
                title: 'Control',
                translate: 'Intencionalidad',
                type: 'item',
                icon: 'schedule',
                url: '/apps/timeControl',
                exactMatch: false
            }
        ]
    },
    {
        id: 'help',
        title: 'Help',
        translate: 'NAV.HELP',
        type: 'group',
        icon: 'pages',
        children: [
            {  
                id: 'email',
                title: 'Contact Email',
                translate: 'NAV.CONTACT_EMAIL',
                type: 'item',
                url: '/apps/contact_email',
                icon: 'contact_support'
            },
            /*{
                id: 'manual',
                title: 'Manual',
                translate: 'NAV.MANUAL',
                type: 'item',
                url: '/apps/help/manual',
                icon: 'import_contacts'
            }*/
        ]
    },
    // {
    //     id: 'otherSections',
    //     title: 'Other Sections',
    //     translate: 'NAV.OTHER_SECTIONS',
    //     type: 'group',
    //     icon: 'apps',
    //     children: [
    //         {
    //             id: 'control',
    //             title: 'Control',
    //             translate: 'NAV.TIME_CONTROL',
    //             type: 'item',
    //             icon: 'schedule',
    //             url: '/apps/timeControl',
    //             exactMatch: false
    //         }
    //     ]
    // }
];