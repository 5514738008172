<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            {{ getEmpresa() }}
            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img *ngIf="!loading" class="avatar mr-0 mr-sm-16" src="assets\images\avatars\default.png">
                    <span *ngIf="!loading" class="username mr-12" fxHide fxShow.gt-sm>{{ globalService.clienteAivoz.razonSocial }}</span>
                    <div *ngIf="loading" class="user-button">
                        <app-loading style="transform: scale(0.5); width: 64px;" class="center"></app-loading>
                    </div>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>
            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item (click)="myProfile()">
                    <mat-icon>account_circle</mat-icon>
                    <span>{{'TOOLBAR.MY_PROFILE' | translate}}</span>
                </button>
 
                <button mat-menu-item (click)="subscription()" *ngIf="cliente && !cliente.proveedor && cliente.email != 'am@smartbiometrik.com'"> 
                    <mat-icon>mail</mat-icon>
                    <span>{{'TOOLBAR.MY_SUBSCRIPTION' | translate}}</span>
                </button>
  <!-- 
             <button mat-menu-item class="">
                    <mat-icon>mail</mat-icon>
                    <span>Inbox</span>
                </button> -->

                <!-- <button mat-menu-item class="" *ngIf="cliente.tipoCliente == 'administrador'" (click)="adminPanel()">
                    <mat-icon>admin_panel_settings</mat-icon>
                    <span>{{'TOOLBAR.ADMIN' | translate}}</span>
                </button> -->

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{'TOOLBAR.LOGOUT' | translate}}</span>
                </button>

            </mat-menu>

            <!-- <div class="toolbar-separator"></div>

            <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

            <div class="toolbar-separator"></div>

            <button mat-button class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>
            </mat-menu>

        </div>


    </div>

</mat-toolbar>