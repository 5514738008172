import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UtilityProvider } from './providers/utility/utility';
import { DataManagement } from './services/dataManagement';
import { RestWS } from './services/restService';
import { GlobalService } from './services/globalService';
import { BuildPDFProvider } from './providers/pdf/buildPDF';
import { CryptProvider } from './providers/crypt/crypt';
import { CookieService } from 'ngx-cookie-service';
import { GoogleCloudVisionServiceProvider } from './providers/vision/google-cloud-vision-service';
import { DialogModule } from './dialogs/dialog/dialog.module';
import { LoadingModule } from './spinners/loading/loading.module';
import { ConfigService } from 'config/configService';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ShareWhatsappModule } from './dialogs/share-whatsapp/share-whatsapp.module';
import { ShareIdCallModule } from './dialogs/share-idcall/share-idcall.module';
import { MatCardModule } from '@angular/material/card';


const config: SocketIoConfig = { url: environment.socketUrl, options: {} };

const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    {
        path: 'pages/maintenance',
        redirectTo: 'pages/auth/login'
    },
    {
        path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'ui',
        loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
    },
    {
        path: 'documentation',
        loadChildren: () => import('./main/documentation/documentation.module').then(m => m.DocumentationModule)
    },
   
    {
        path: '**',
        redirectTo: 'pages/auth/login'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DialogModule,
        ShareWhatsappModule,
        ShareIdCallModule,
        LoadingModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatCardModule,
        
        RouterModule.forRoot(appRoutes, { useHash: true }),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        PdfViewerModule,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,

        // Firebase
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule
    ],
    providers: [
        RestWS,
        DataManagement,
        UtilityProvider,
        Title,
        GlobalService,
        ConfigService,
        BuildPDFProvider,
        CryptProvider,
        CookieService,
        GoogleCloudVisionServiceProvider,
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
