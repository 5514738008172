import { Plantilla, Evento } from './data.model';

export class ErroresFormularioRegistro {
    tipoDocumento: string;
    numIdentificacion: string;
    fechaExpedicion: string;
    nombre: string;
    apellido1: string;
    apellido2: string;
    fechaNacimiento: string;
    sexo: string;
    nacionalidad: string;
    tieneErrores: boolean;
}

export class PasoAnterior {
    paso: string;
    progreso: string;
}

export class DatosDocumento {
    nombre: string;
    apellido1: string;
    apellido2: string;
    pais: string;
    documento: string;
    sexo: string;
    nacimiento: string;
    expedicion: string;
    tipoDocumento: string;
    estadoDocumento: "nulo" | "posible" | "seguro";
    estadoNombre: "nulo" | "posible" | "seguro";
}

export class TipoDocumento {
    tipo: string;
    continente: string;
    pais: string;
}

export class Continente {
    nombre: string;

    constructor(nombre) {
        this.nombre = nombre;
    }
}

export class Pais {
    continente: Continente;
    nombre: string;

    constructor(nombreContinente, nombrePais) {
        this.continente = new Continente(nombreContinente);
        this.nombre = nombrePais;
    }
}

export class PDF {
    funciones: Funcion[];
    propiedades: Propiedades;
    constructor(contenido) {
        this.funciones = contenido.funciones;
    }
}

export class Funcion {
    nombre: string;
    atributos: string[];
    numero: number;
    rellenable: Rellenable;
}

export class Rellenable {
    valor: string;
    tipo: string;
    estado: boolean;
}

export class Propiedades {
    title: string;
    subject: string;
    author: string;
    keywords: string;
    creator: string;
}

export class ToastOptions {
    header: string;
    message: string;
    position: 'top' | 'bottom' | 'middle';
    duration: number;
}

export class DialogData {
    title: string;
    message: string;
    accept: boolean;
    textAccept: string;
    otherData: any;
}

export class ShareWhatsappData {
    text: string;
}

export class ShareIdCallData {
    enlaceUsuario: string;
    enlaceAdministrador: string;
    evento: Evento;
}

export class GrupoEventos {
    id: number;
    title: string;
    type: string;
    template: Plantilla;
    events: Evento[];
    
    /**
     * Número de firmas completadas
     */
    status: number;
    /**
     * Número de firmas total, sin contar las firmas canceladas
     */
    totalAvailable: number;
    date: Date;
    button: any;
    /**
     * Corresponde al id del primer evento que entra en el grupo.
     * 
     * Se utilizará para acceder via url al grupo, y poder realizar la búsqueda del grupo que contenga dicho evento.
     */
    idEvento: string;

    /**
     * Constructor de grupo de eventos.
     * El atributo "template" habrá que rellenarlo manualmente tras su creación.
     * El atributo "status" queda actualizado con el primer evento
     * 
     * @param evento Si se recibe, se actualizan todos los datos con el evento
     * @param contador Si se recibe, el id se sobreescribe con el número del contador
     */
    constructor(evento?: Evento, contador?: number) {
        evento = evento || new Evento();
        contador = contador || 0;

        this.id = contador;
        this.title = evento.titulo || "";
        this.type = evento.tipo || "";
        if (evento.estado == 'firmado') {
            this.status = 1;
            this.totalAvailable = 1;
        } else if (evento.estado == 'cancelado') {
            this.status = 0;
            this.totalAvailable = 0;
        } else {
            this.status = 0;
            this.totalAvailable = 1;
        }
        this.template = null;
        this.events = [];
        if (evento._id) {
            this.idEvento = evento._id;
            this.events.push(evento);
        }
        this.date = evento.fechaCreacion;
    }

    /**
     * Función para añadir más eventos al grupo
     * 
     * El status se actualizará de manera automática.
     * 
     * @param evento 
     */
    addNewEvent(evento: Evento) {

        this.events.push(evento);

        if (evento.estado == 'firmado') {
            this.status++;
            this.totalAvailable++;
        } else if (evento.estado != 'cancelado') {
            this.totalAvailable++;
        }
        // Añadimos el idEvento en caso de que sea el primer evento.
        if (this.events.length == 0) {
            this.idEvento = evento._id;
        }
    }
}

export class Campaings {
    idCampaign: string;
    

    constructor() {
        
    }
}


export class Calls {
    Fecha: string;
    Destino: string; 
    Duracion: string;
    Estado: string;
    Tipificado: string;
    info: string;

    constructor() {
        this.Fecha = '2024-01-01';
        
    }
}

export class Dataset {
    year: number;
    label: string;
    data: number[];
    fill: string;

    constructor(year) {
        this.year = year;
        this.label = '';
        this.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.fill = 'start';
    }
}

// ENUMERADOS
export enum PasoEnvio {
    numeroFirmantes = "numeroFirmantes",
    elegirMetodo = "elegirMetodo",
    elegirTipo = "elegirTipo",
    datosEnvio = "datosEnvio",
    notificarUsuario = "notificarUsuario",
    escribirCorreos = "escribirCorreos"
}

export enum SeccionFormCreateFolder {
    loading = "loading",
    principal = "principal",
    checkList = "checkList",
}

export enum SeccionFormCreateCheckList {
    loading = "loading",
    principal = "principal",
    checkList = "checkList",
}

export enum Etiqueta {
    SPAN = "span",
    H1 = "h1",
    H2 = "h2",
    H3 = "h3",
    I = "i",
    U = "u",
    MARK = "mark",
    S = "s",
    TH = "th",
    TD = "td",
    STRONG = "strong",
    P = "p",
    BLOCKQUOTE = "blockquote",
    LI = "li",
    OL = "ol",
    UL = "ul",
    A = "a",
    TR = "tr",
    THEAD = "thead",
    TBODY = "tbody",
    TABLE = "table",
    FIGURE = "figure"
}